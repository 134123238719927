import React from 'react';
import {
  Accordion,
  Column,
  Container,
  Layout,
  LayoutBlock,
  Hero,
  Title,
  Description,
  Button,
  Row,
  Section,
  Actions,
} from '@dragonchain-dev/dragon-kit';
import { Layout as PageLayout, SEO, TypeForm, FeatureRow } from '../../components/_index';

import Blockchain from '../../assets/images/icons/blockchain-b.svg';
import Flexible from '../../assets/images/icons/flexible.svg';
import RewardA from '../../assets/images/icons/reward.svg';
import RewardB from '../../assets/images/icons/reward-b.svg';
import Money from '../../assets/images/icons/money.svg';
import Shield from '../../assets/images/icons/shield.svg';
import FastArrows from '../../assets/images/icons/fast-arrows.svg';

const NFT = () => {
  return (
    <PageLayout>
      <SEO
        title="Supercharge your enterprise systems with Enterprise NFTs"
        description="Scalable NFT for the Enterprise Environment..."
      />
      <Layout dark background="blue">
        <Hero type="code">
          <div className="caption">Enterprise NFT</div>
          <Title color="white">Scalable NFT for the Enterprise</Title>
          <Description color="blue-light">
            Enterprise NFTs on Dragonchain are much more than just consumer collectibles. From tracking supply chain information, to settling money transfers, to controlling access to data between organizations, Enterprise NFTs will revolutionize asset management and ownership.

          </Description>
        </Hero>
      </Layout>

      <LayoutBlock separator>
        <Container>
          <Row gap="small">
            <Column span={6}>
              <Section title="Features" />
            </Column>
          </Row>
          <FeatureRow
            features={[
              {
                Icon: Blockchain,
                title: 'Scalable',
                body:
                  'Create millions or billions of Non Fungible Tokens with advanced capabilities.',
              },
              {
                Icon: Money,
                title: 'Low Cost',
                body:
                  'Stable and locked fees to create and transfer Enterprise NFT.',
              },
              {
                Icon: Shield,
                title: 'High Security',
                body:
                  'Only Dragonchain leverages multiple blockchains (Bitcoin & Ethereum) to secure ownership and information related to an Enterprise NFT.',
              },
              {
                Icon: Flexible,
                title: 'Supply Chain',
                body:
                  'Use Enterprise NFT to track and control access to information in a complex supply chain, improving the supply chain experience and ROI.',
              },
              {
                Icon: RewardA,
                title: 'Asset Tracking',
                body:
                  'Control value transfers with powerful tracking capabilities.',
              },
              {
                Icon: Money,
                title: 'Payments and Monetary Transfers',
                body: 'Cut out the middleman in monetary and payments systems.',
              },
              {
                Icon: FastArrows,
                title: 'Exportability',
                body: 'Allow export of any Enterprise NFT to other networks and platforms (Ethereum, OpenSea, Rarible) with controlled cost.',
              },
              {
                Icon: RewardB,
                title: 'Collectibles',
                body: 'Produce consumer NFTs at scale (collectible, tradeable, merchandise) for your loyal customers.',
              },
            ]}
          />
          <Row gap="large">
            <Column span={12}>
              <Accordion title="Frequently Asked Questions">
                <Accordion.Item title="What is the difference between Ethereum standard NFTs and Dragonchain Enterprise NFT?">
                  Enterprise NFTs provide more flexibility in use and lower operational cost than Ethereum based NFTs with the added ability to leverage the Ethereum NFT ecosystem at will.
                </Accordion.Item>
                <Accordion.Item title="How do you scale?"
                  children={
                    <p>
                      Dragonchain has unique scaling technologies allowing high volume with controlled costs as proven in our <Button className="hilight-text" to="https://www.youtube.com/watch?v=pSfBid1_US4">live 24 hour opperational sclaing demonstaration</Button>
                    </p>
                  }
                >
                </Accordion.Item>
                <Accordion.Item title="Are Enterprise NFT interoperable with other platforms?"
                  children={
                    <p>
                      Yes, thanks to the operational technology behind <Button className="hilight-text" to="https://dragonchain.com/blog/interchain">Dragonchain's Interchain</Button> patent <Button className="hilight-text" to="https://patents.google.com/patent/US10666426B2/en">US10666426B2</Button> Enterprise NFTs can be exported to any popular NFT platform.

                    </p>
                  }
                >
                </Accordion.Item>
              </Accordion>
            </Column>
          </Row>
          <Row>
            <Column span={6}>
              <Section title="Integrate at Scale Today">
                Looking to scale your asset tracking systems? Schedule a free consultation with the experts.

              </Section>
            </Column>
            <Column span={6} right middle>
              <Actions
                actions={[
                  {
                    type: 'link',
                    name: 'Contact Us',
                    color: 'blue',
                    hover: 'blue-dark',
                    to: '/contact-us',
                  },
                ]}
              />
            </Column>
          </Row>
        </Container>
      </LayoutBlock>
    </PageLayout>
  );
};

export default NFT;
